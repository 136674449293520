type Paginator<ITEM, RESULT> = {
    getPage: (input: { locale: string; skip: number; limit: number }) => Promise<RESULT>
    items: (result: RESULT) => ITEM[]
    total: (result: RESULT) => number
    locale: string
    batch: number
}

export async function paginate<ITEM, RESULT>({
    getPage,
    items,
    total,
    locale,
    batch
}: Paginator<ITEM, RESULT>): Promise<ITEM[]> {
    const pages: ITEM[] = []

    let skip = 0
    while (true) {
        const result = await getPage({
            locale: locale,
            skip,
            limit: batch
        })
        pages.push(...items(result))
        skip = skip += batch
        if (skip >= total(result)) {
            break
        }
    }

    return pages
}
