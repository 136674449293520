import type * as entity from '@bob/entities'

import cold from '../cold.json'

export const defaultLocale: entity.cold.ColdData['defaultLocale'] = cold.defaultLocale
export const locales: entity.cold.ColdData['locales'] = cold.locales

export const aboutPages: entity.cold.ColdData['aboutPages'] = cold.aboutPages as any
export const brands: entity.cold.ColdData['brands'] = cold.brands as any
export const categories: entity.cold.ColdData['categories'] = cold.categories as any
export const notification: entity.cold.ColdData['notification'] = cold.notification as any
export const tags: entity.cold.ColdData['tags'] = cold.tags as any
export const taxonomies: entity.cold.ColdData['taxonomies'] = cold.taxonomies as any
