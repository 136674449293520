import type * as entity from '@bob/entities'

import cold from '../cold-client.json'

export const defaultLocale: entity.cold.SlimColdData['defaultLocale'] = cold.defaultLocale
export const locales: entity.cold.SlimColdData['locales'] = cold.locales

export const aboutPages: entity.cold.SlimColdData['aboutPages'] = cold.aboutPages as any
export const brands: entity.cold.SlimColdData['brands'] = cold.brands as any
export const categories: entity.cold.SlimColdData['categories'] = cold.categories as any
export const notification: entity.cold.ColdData['notification'] = cold.notification as any
export const tags: entity.cold.SlimColdData['tags'] = cold.tags as any
export const taxonomies: entity.cold.SlimColdData['taxonomies'] = cold.taxonomies as any
